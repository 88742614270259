body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f8f6ec; /* Couleur de fond crème moderne */
    color: #333;
}

.text-highlight {
    color: #7045af !important;
}

header .logo {
    max-width: 300px; /* Augmenter la taille du logo */
}

header {
    padding-top: 40px; /* Increase padding for more space */
    padding-bottom: 40px; /* Increase padding for more space */
}

.hero {
    margin-bottom: 40px; /* Increase margin for more space */
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-image {
    max-width: 400px; /* Double the size of the image */
    height: auto;
    margin-right: 20px; /* Add some space between the image and text */
}

.hero h2, .hero p {
    color: #000;
}

.icon-card {
    position: relative;
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 2rem; /* Plus d'espace entre les lignes */
    height: 100%;
    display: flex;
    align-items: center;
}

.icon-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.icon-color {
    color: #7045af; /* Couleur des icônes */
    transition: color 0.3s;
}

.carousel-inner {
    height: auto; /* Reset carousel height to standard */
}

footer {
    background-color: #333;
    color: #fff;
}

footer p {
    margin: 0;
    padding: 10px 0;
}

.container.my-5 {
  max-width: 600px;
}

.card {
  border: none;
  border-radius: 10px;
}

.btn-block {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.btn-link {
  color: #007bff;
  font-size: 14px;
  padding: 0;
}

.btn-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.card-title {
  font-weight: bold;
  color: #333;
}
.phone-input-wrapper {
  width: 100%;
  max-width: 100%;
}

.react-tel-input .form-control {
  width: 100%!important;
  box-sizing: border-box;
  padding-left: 25px; /* Ajustement pour compenser la largeur du dropdown du code pays */
  border-radius: 4px; /* Assurez-vous que le champ a la même bordure arrondie que les autres */
}

.react-tel-input .flag-dropdown {
  margin-right: 8px; /* Ajoute de l'espace entre le dropdown et le champ de texte */
}

.react-tel-input {
  width: 100%;
}

.react-tel-input .form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

